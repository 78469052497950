import { $ } from '@situation/setdesign.util';

$(document).one('TicketCalendar.init', (e, TicketCalendar, $on) => {
    $on(TicketCalendar.FILTER_DEFAULTS, (defaults) => {
        defaults.update({
            // set to the $tc-theme-name sass variable
            theme: 'kakimbo-theme',
            'disable-default-analytics': true,
            // set a different width to switch to desktop view
            /* 'breakpoint': 1025, */
            // use a parent container if components are separated
            /* 'vue-parent': $('.js-ticket-calendar-wrap').length
            ? '.js-ticket-calendar-wrap' : '.ticket-calendar', */
            // change the aspect ratio of calendar date cells
            /* 'fullcalendar': {
                aspectRatio: 1.1
            }, */
            // change the height of scroll wheel rows
            /* 'mobiscroll': {
                height: 35
            }, */
            // change properties of the tag highlighter
            /* 'tag-highlighter': {
                instructionText: '<p>Filter above to see best options!</p>'
            }, */
            // add a custom arrow svg icon with class called .tc-accordion__toggle-custom
            /* 'accordion-month-header' : {
                arrowSvgName: 'icon-arrow'
            }, */
            'calendar-week-header': { headerFormat: 'ddd, MMM D' },
            // modify the event urls
            /* 'filter-url': function(url) {
                var deferred = $.Deferred();
                (function() {
                    //do something asynchronously with the url
                    deferred.resolve(url);
                })();
                return deferred.promise();
            }, */
            // change properties of the selected event component
            'selected-event': {
                buttonClass: 'button',
                eventFormat(event) {
                    return `${event.format(
                        'ddd, MMM DD',
                    )} at <span class="tc-selected-event__time">${event.format(
                        event.smartTimeFormat(''),
                    )}</span><span class="tc-selected-event__meridiem">${event.format('A')}</span>`;
                },
            },
        });
    });
});
